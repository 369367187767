var exports = {};
//Types of elements found in the DOM
exports = {
  Text: "text",
  //Text
  Directive: "directive",
  //<? ... ?>
  Comment: "comment",
  //<!-- ... -->
  Script: "script",
  //<script> tags
  Style: "style",
  //<style> tags
  Tag: "tag",
  //Any tag
  CDATA: "cdata",
  //<![CDATA[ ... ]]>
  Doctype: "doctype",
  isTag: function (elem) {
    return elem.type === "tag" || elem.type === "script" || elem.type === "style";
  }
};
export default exports;